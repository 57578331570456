//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    marker: {
      type: Array,
      required: true,
    },
    zoom: {
      type: Number,
      default: 7,
    },
    disableGestureHandling: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const mapOptions = computed(() => ({
      zoomSnap: 0.5,
      gestureHandling: !props.disableGestureHandling,
    }))

    return { mapOptions }
  },
})
