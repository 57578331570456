import { render, staticRenderFns } from "./ecLocation.vue?vue&type=template&id=1247e4b2&scoped=true&lang=pug&"
import script from "./ecLocation.vue?vue&type=script&lang=js&"
export * from "./ecLocation.vue?vue&type=script&lang=js&"
import style0 from "./ecLocation.vue?vue&type=style&index=0&id=1247e4b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1247e4b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LTileLayer: require('/home/runner/work/EC-Website/EC-Website/node_modules/@ec-nordbund/nuxt-vue2-leaflet/dist/LTileLayer.js').default,LControlScale: require('/home/runner/work/EC-Website/EC-Website/node_modules/@ec-nordbund/nuxt-vue2-leaflet/dist/LControlScale.js').default,LPopup: require('/home/runner/work/EC-Website/EC-Website/node_modules/@ec-nordbund/nuxt-vue2-leaflet/dist/LPopup.js').default,LMarker: require('/home/runner/work/EC-Website/EC-Website/node_modules/@ec-nordbund/nuxt-vue2-leaflet/dist/LMarker.js').default,LMap: require('/home/runner/work/EC-Website/EC-Website/node_modules/@ec-nordbund/nuxt-vue2-leaflet/dist/LMap.js').default})
