import { render, staticRenderFns } from "./karlsminde.vue?vue&type=template&id=3481c760&scoped=true&lang=pug&"
import script from "./karlsminde.vue?vue&type=script&lang=js&"
export * from "./karlsminde.vue?vue&type=script&lang=js&"
import style0 from "./karlsminde.vue?vue&type=style&index=0&id=3481c760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3481c760",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VContainer: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VContainer.js').default,VImg: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VImg/VImg.js').default,VCol: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VCol.js').default,VCarouselItem: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VCarousel/VCarouselItem.js').default,VCarousel: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VCarousel/VCarousel.js').default,VRow: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VRow.js').default,EcLocation: require('/home/runner/work/EC-Website/EC-Website/components/ecLocation.vue').default,VBtn: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VBtn/VBtn.js').default})
